/*//=require components/example.js*/

//=require ../components/**.*


$(document).ready(function(){

    var phonemaskOk = 0;

    $(".MaskedPhone").inputmask("8 (999) 999-99-99",{
        "oncomplete": function(){ phonemaskOk = 1; },
        "onincomplete": function(){ phonemaskOk = 0; },
        "oncleared": function(){ phonemaskOk = 0; }
    });

    $('input').keypress(function(e){
        if(e.keyCode==13)
        {
            e.preventDefault();
            var qid = $(this).parents('form').attr('alt');
            var qreachgoal = $(this).parents('form').attr('data-reachgoal');
            formcheck(qid, qreachgoal);
        }
    });

    $(".FancyBtn").fancybox({
        padding: 0
    });

    $(".FancyMedia").fancybox({
        padding: 0
    });

    $(".ProcessingPersonalDataText-Link, .ImPolitic").fancybox({
        maxWidth : 700
    });

    $('.ForThoseSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: false,
        prevArrow: '<div class="Slider-Arrow Slider-Arrow_prev">\n' +
        '                        <img src="/wp-content/themes/nutrix/img/Slider-Arrow-Icon.png"\n' +
        '                             alt=""\n' +
        '                             class="Slider-SlideImg Slider-Arrow-Icon">\n' +
        '                    </div>',
        nextArrow: '<div class="Slider-Arrow Slider-Arrow_next">\n' +
        '                        <img src="/wp-content/themes/nutrix/img/Slider-Arrow-Icon.png"\n' +
        '                             alt=""\n' +
        '                             class="Slider-SlideImg Slider-Arrow-Icon">\n' +
        '                    </div>'
    });

    $('.StarRecommendSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        mobileFirst: true,
        prevArrow: '<div class="StarRecommendSlider-Arrow StarRecommendSlider-Arrow_prev">\n' +
        '                        <img src="/wp-content/themes/nutrix/img/Slider-Arrow-Icon.png"\n' +
        '                             alt=""\n' +
        '                             class="StarRecommendSlider-SlideImg StarRecommendSlider-Arrow-Icon">\n' +
        '                    </div>',
        nextArrow: '<div class="StarRecommendSlider-Arrow StarRecommendSlider-Arrow_next">\n' +
        '                        <img src="/wp-content/themes/nutrix/img/Slider-Arrow-Icon.png"\n' +
        '                             alt=""\n' +
        '                             class="StarRecommendSlider-SlideImg StarRecommendSlider-Arrow-Icon">\n' +
        '                    </div>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    swipe: false,
                    // speed: 500,
                    // fade: true,
                    // cssEase: 'linear'
                    // slidesToShow: 1,
                    // slidesToScroll: 1,
                    // centerMode: true,
                    // variableWidth: true
                }
            },
        ]
    });

    $('.FormSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        prevArrow: '',
        nextArrow: '<button type="button" class="FormSlider-Arrow FormSlider-Next Btn">' +
        'Продолжить <img src="/wp-content/themes/nutrix/img/SliderArrow.png" alt="">' +
        '</button>'
    });

    /*var myDefMenu = new DefMenu({
        btn : ".DefmenuBtn"
        //menu : ".mydefmenu",
        //build : true,
        //links: "auto"
    });*/

    var myDefMenu = new DefMenu({
        btn: ".DefmenuBtn",
        menu: ".BurgerMenu"
        //build : true,
        //links: "auto"
    });

    $('.BurgerMenu-Close').on('click', function () {
        myDefMenu.close();
    });

    $('.Accordion-Title').on('click', function () {
        $(this).parent().toggleClass('Accordion-Item_open');
    });

    function animatedAnchorScroll(self) {
        var target = $(self).attr('href');
        $('html, body').animate({ scrollTop: $(target).offset().top-80}, 500);
    }
    $('a[href^="#"]').click(function () {
        animatedAnchorScroll(this);
        myDefMenu.close();
        return false;
    });

    validateForm({
        formId: 'form-need-help'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });

    /*var center = [54.728243, 55.955951];
    var iconCoord = [54.728243, 55.955951];
    mapInit(center, iconCoord);*/
});

